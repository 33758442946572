<template>
  <CSidebar
      fixed
      :minimize="minimize"
      :show="show"
      @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/dashboard">

      <CIcon
          class="c-sidebar-brand-full"
          name="logo"
          size="custom-size"
          :height="35"
          viewBox="0 0 710 120"
      />
      <CIcon
          class="c-sidebar-brand-minimized"
          name="logo2"
          size="custom-size"
          :height="35"
          viewBox="0 0 110 134"
      />
    </CSidebarBrand>
    <CRenderFunction flat :content-to-render="sideMenus"/>
  </CSidebar>
</template>

<script>
import nav from './_nav'
import {menus} from "/src/store/url.js";
import {Mixin} from "../mixins";
export default {
  name: 'TheSidebar',
  mixins: [Mixin],
  nav,
  data() {
    return {
      sideMenus: [],
      module: menus,
    }
  },
  mounted() {
    let self = this;
    axios.get(this.listUrlApi(this.module), self.model)
        .then(function (response) {
          self.sideMenus = [response.data];
        })
        .catch(function () {
          self.$router.push("/logout");
        });
  },
  computed: {
    show() {
      return this.$store.state.sidebarShow
    },
    minimize() {
      return this.$store.state.sidebarMinimize
    }
  }
}
</script>
