<template>
  <CDropdown
      inNav
      class="c-header-nav-items"
      placement="bottom-end"
      add-menu-classes="pt-0"
  >
    <template #toggler>

      <CHeaderNavLink>
        <CIcon name="cil-bell"/>
        <CBadge color="primary" class="mr-auto">{{ notificationCount }}</CBadge>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center primary-text" color="light">
      <strong>{{ $lang.dashboard.form.notification }}</strong>
    </CDropdownHeader>
    <div v-if="notificationCount > 0">
      <div style="padding:5px 0px; float :left; width:  100%;" v-for="(notification,index) in notifications" :key="index">
        <CDropdownItem disabled>
          <span v-html="notification.notification"></span>
        </CDropdownItem>
      </div>
    </div>
    <div v-else>
      <div style="padding:5px 0px; float :left; width:  100%;">
        <CDropdownItem disabled>
          {{ $lang.common.label.not_found }}
        </CDropdownItem>
      </div>
    </div>
    <CDropdownItem to="/notifications/alerts" class="view-all-notification" >
      <span>
        {{ $lang.dashboard.header.view_all }}
      </span>
    </CDropdownItem>
  </CDropdown>
</template>
<script>
import {General} from "/src/store/url.js";
import {Mixin} from "../mixins";

export default {
  name: 'NotificationItem',
  mixins: [Mixin],
  data() {
    return {
      id: localStorage.getItem('userId'),
      notifications: [],
      notificationCount: '',
      totalCount: '',
      module: General,
    }
  },
  mounted() {
    let self = this;
    axios.get(this.notificationUrlApi(this.module), self.model)
        .then(function (response) {
          if (response.status === 200) {
            self.notifications = response.data.data.notification;
            self.notificationCount = response.data.data.notificationCount;
            self.totalCount = response.data.data.totalCount;
          }
        });
  },
}
</script>
<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
