export default [
    {
        "_name": "CSidebarNav",
        "_children": [
            {
                "id": 16,
                "name": "Posts",
                "icon": "la la-user-plus",
                "link": "",
                "parent_id": 0,
                "hasChild": 0,
                "_name": "CSidebarNavItem",
                "to": "posts"
            },
            {
                "id": 17,
                "name": "Opportunities",
                "icon": "la la-user-plus",
                "link": "",
                "parent_id": 0,
                "hasChild": 0,
                "_name": "CSidebarNavItem",
                "to": "projects"
            },
            {
                "id": 18,
                "name": "Users",
                "icon": "la la-user-plus",
                "link": "",
                "parent_id": 0,
                "hasChild": 0,
                "_name": "CSidebarNavItem",
                "to": "users"
            },
            {
                "id": 19,
                "name": "Profiles",
                "icon": "la la-user-plus",
                "link": "",
                "parent_id": 0,
                "hasChild": 0,
                "_name": "CSidebarNavItem",
                "to": "profile"
            },
            {
                "id": 20,
                "name": "Skills",
                "icon": "la la-user-plus",
                "link": "",
                "parent_id": 0,
                "hasChild": 0,
                "_name": "CSidebarNavItem",
                "to": "skills"
            },
            {
                "id": 21,
                "name": "Business Traveller",
                "icon": "la la-user-plus",
                "link": "",
                "parent_id": 0,
                "hasChild": 0,
                "_name": "CSidebarNavItem",
                "to": "business-traveller"
            },
            {
                "id": 22,
                "name": "Role Management",
                "icon": "la la-user-plus",
                "link": "",
                "parent_id": 0,
                "hasChild": 0,
                "_name": "CSidebarNavItem",
                "to": "roles"
            },
            {
                "id": 1,
                "name": "Dashboard",
                "icon": "dashboard-icon",
                "link": null,
                "parent_id": 0,
                "hasChild": 0,
                "_name": "CSidebarNavItem",
                "to": 'dashboard'
            },
            {
                "id": 2,
                "name": "Masters",
                "icon": "la la-user",
                "link": "",
                "parent_id": 0,
                "hasChild": 1,
                "_name": "CSidebarNavDropdown",
                "route": "/",
                "items": [
                    {
                        "id": 3,
                        "name": "Work Type",
                        "icon": "new-roles-icon",
                        "link": "",
                        "parent_id": 2,
                        "hasChild": 0,
                        "_name": "CSidebarNavItem",
                        "to": "worktype"
                    },
                    {
                        "id": 4,
                        "name": "Company Type",
                        "icon": "",
                        "link": "admin.user.index",
                        "parent_id": 2,
                        "hasChild": 0,
                        "_name": "CSidebarNavItem",
                        "to": "companytype"
                    },
                    {
                        "id": 5,
                        "name": "Priority",
                        "icon": "la la-line-chart",
                        "link": "",
                        "parent_id": 2,
                        "hasChild": 1,
                        "_name": "CSidebarNavDropdown",
                        "to": "priority"
                    },
                    {
                        "id": 6,
                        "name": "Duration",
                        "icon": "",
                        "link": "",
                        "parent_id": 2,
                        "hasChild": 0,
                        "_name": "CSidebarNavItem",
                        "to": "duration"
                    },
                    {
                        "id": 7,
                        "name": "Currency Type",
                        "icon": "new-events-icon",
                        "link": "",
                        "parent_id": 2,
                        "hasChild": 0,
                        "_name": "CSidebarNavItem",
                        "to": "currencytype"
                    },
                    {
                        "id": 8,
                        "name": "Social Platform",
                        "icon": "",
                        "link": "",
                        "parent_id": 2,
                        "hasChild": 0,
                        "_name": "CSidebarNavItem",
                        "to": "socialplatform"
                    },
                    {
                        "id": 9,
                        "name": "Resource Type",
                        "icon": "la la-calendar-check-o",
                        "link": "",
                        "parent_id": 2,
                        "hasChild": 1,
                        "_name": "CSidebarNavDropdown",
                        "route": "resourcetype"
                    },
                    {
                        "id": 10,
                        "name": "Country",
                        "icon": "new-employee-attendance-icon",
                        "link": "",
                        "parent_id": 2,
                        "hasChild": 0,
                        "_name": "CSidebarNavItem",
                        "to": "country"
                    },
                    {
                        "id": 11,
                        "name": "State",
                        "icon": "new-employee-attendance-icon",
                        "link": "",
                        "parent_id": 2,
                        "hasChild": 0,
                        "_name": "CSidebarNavItem",
                        "to": "state"
                    },
                    {
                        "id": 12,
                        "name": "City",
                        "icon": "la la-sitemap",
                        "link": "",
                        "parent_id": 2,
                        "hasChild": 1,
                        "_name": "CSidebarNavDropdown",
                        "route": "city"
                    },
                    {
                        "id": 13,
                        "name": "Industry",
                        "icon": "new-inventory-icon",
                        "link": "",
                        "parent_id": 2,
                        "hasChild": 0,
                        "_name": "CSidebarNavItem",
                        "to": "industry"
                    },
                    {
                        "id": 14,
                        "name": "Report Reason",
                        "icon": "la la-sitemap",
                        "link": "",
                        "parent_id": 2,
                        "hasChild": 0,
                        "_name": "CSidebarNavItem",
                        "to": "reportreason"
                    },
                    {
                        "id": 15,
                        "name": "Notification",
                        "icon": "la la-sitemap",
                        "link": "",
                        "parent_id": 2,
                        "hasChild": 0,
                        "_name": "CSidebarNavItem",
                        "to": "notification"
                    }
                ]
            }
        ]
    }
]