<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">

          <img
            :src="avatarImageURL"
            alt="Avtar Image"
            class="c-avatar-img"
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownItem v-on:click="profileChanged()">
      <CIcon name="cil-user" /> <span> Profile </span>
    </CDropdownItem>
    <CDropdownItem v-on:click="logout()">
      <CIcon name="cil-lock-locked" /> <span> Logout </span>
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { serverBus } from "../main";

export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return {
      itemsCount: 42,
      avatarImageURL: null,
    };
  },
  mounted() {
    let self = this;

    self.avatarImageURL = localStorage.getItem("avatar");
    serverBus.$on("PASS_THE_IMAGE", function (url) {
      localStorage.setItem("avatar", url);
      self.avatarImageURL = url;
    });

    // this.geolocate();
  },
  methods: {
    profileChanged() {
      this.$router.push({ name: "adminProfile" });
    },
    changedPassword() {
      this.$router.push({ name: "ChangePassword" });
    },
    logout() {
      this.$router.push({ name: "Logout" });
    },
  },
};
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>